.chat-page {
  background-color: #1E2B4E;

  .logo {
    padding: 10px 0;
    text-align: center;

    img {
      width: 140px;
    }
  }

  .content {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
  }
}