@import "../paper-dashboard/_variables.scss";
.bsidebar .wrapper {
    display: block;
}

.bsidebar#sidebar {
    min-width: 35vw;
    max-width: 35vw;
    height: 100vh;
    position: fixed;
    display: none;
    top: 0;
    right: 0;
    /* top layer */
    z-index: 1030;
    background: #fefefe;
    transition: all 0.3s;
}

.bsidebar#sidebar.active {
    display: block;
}

.bsidebar-overlay-active {
    display: block;
    position: fixed;
    /* full screen */
    top:0px;
    left: 0px;
    width: calc(100vw - 260px);
    height: 100%;
    /* transparent black */
    background: rgba(0, 0, 0, 0.7);
    z-index: 1029;
    opacity: 1;
    /* animate the transition */
    transition: all 0.5s ease-in-out;
}

/* display .overlay when it has the .active class */
.bsidebar-overlay-inactive {
    display: none;
    opacity: 0;
}

.bsidebar #dismiss {
    width: 35px;
    height: 35px;
    position: absolute;
    cursor: pointer;
    /* top right corner of the sidebar */
    top: 15px;
    font-size: 15px;
    color: #fff;
    right: 4px;
}

.bsidebar#sidebar .sidebar-header {
    padding: 15px 15px 15px 15px;
    background: $selfguru-bg;
    color: #fff;
}
.bsidebar#sidebar .sidebar-header  h3{
    margin-bottom: 0px;
}


.select-profile {
    margin-left: 30px;
    margin-right: 30px;
    color: #000000;
    //background-color: #FFFFFF;
}
