.standards-calendar {
  .rbc-toolbar {
    .rbc-btn-group:first-child :first-child {
      display: none;
    }
    .rbc-btn-group:last-child {
      display: none;
    }
  }

  .rbc-allday-cell {
    display: none;
  }
 
  .rbc-time-header-cell {
    display: none;
  }

  .rbc-day-slot .rbc-event {
    min-height: 40px;
  }
}

.form-calendar-header {
  width: 100%;

  .form-group:first-child {
    flex: 1
  }
}