:root {

    --selfguru-color-lumi: #0E398D;
    // --selfguru-bg-lumi: rgb(89, 124, 226);
    --selfguru-bg-lumi: rgb(14, 57, 141);
    --font-color-lumi:                 #66615b;
    --fill-font-color-lumi:            rgba(255, 255, 255, 0.8);
    --font-family-sans-serif-lumi:     'Space Grotesk', 'Montserrat', "Helvetica", Arial, sans-serif;
    --sans-serif-family-lumi:          'Space Grotesk', 'Montserrat', 'Helvetica Neue',  Arial, sans-serif;
    --primary-color-lumi: #0E398D;
    --warning-color:#ed4056;
}
:export{

    --selfguru-color:             $selfguru-color;
    --selfguru-bg:                $selfguru-bg;
    --font-color:                 $font-color;
    --fill-font-color:            $fill-font-color;
    --font-family-sans-serif:     $font-family-sans-serif;
    --sans-serif-family:          $sans-serif-family;
    --primary-color: $primary-color;
    --warning-color: $warning-color;

}
