:root {

    --selfguru-color-grou: #1E2B4E;
    --selfguru-bg-grou: rgb(14, 57, 141);
    --font-color-grou:                 #66615b;
    --fill-font-color-grou:            rgba(255, 255, 255, 0.8);
    --font-family-sans-serif-grou:     'Unbounded', 'Montserrat', "Helvetica", Arial, sans-serif;
    --sans-serif-family-grou:          'Unbounded', 'Montserrat', 'Helvetica Neue',  Arial, sans-serif;
    --primary-color-grou: #1E2B4E !important;
    --primary-color-d10p-grou: #1b2746 !important;
    --primary-color-l07p-grou: #263662 !important;
    //--primary-color: #51cbce;
    --primary-color-d10p: #1b2746;
    --primary-color-l07p: #263662;


    --warning-color-grou: #ed4056 !important;
    --warning-states-color-d10-p-grou: #ea243e !important;
    --warning-states-color-l07-p: #ee4d62 !important;
    --warning-color-l05-p: #ee4a5e !important;
    --warning-color-d03-p: #ec384f !important;


    --info-color-grou: #1146ad;
    --info-states-color-d10p: #0f3f9c;
    --bg-info-l07p: #134ec1;
    --bg-info-l05p: #124cbc;
    --info-color-d03p: #1044a8;
}
:export{

    --selfguru-color:             $selfguru-color;
    --selfguru-bg:                $selfguru-bg;
    --font-color:                 $font-color;
    --fill-font-color:            $fill-font-color;
    --font-family-sans-serif:     $font-family-sans-serif;
    --sans-serif-family:          $sans-serif-family;
    --primary-color: $primary-color-grou;
    //--primary-color-d10p: $primary-color;

    --warning-color: $warning-color;
    --info-color: $info-color-grou;
    --warning-states-color-d10-p: $warning-states-color;

}
//$warning-color: var(--warning-color-grou) !default;
//$warning-states-color: var(--warning-states-color-d10-p-grou) !default;
//$bg-warning: var(--warning-states-color-l07-p-grou) !default;
