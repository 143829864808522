.appointment-show-container {
  .card-footer {
    .btn {
      margin-bottom: 10px !important;
      margin-right: 10px !important;
    }
  }

  dd {
    font-size: 1.2em;
  }

  .card-testimonial {
    margin-top: 0;
  }
}