.setup-page {
  background-color: #f4f3ef;
  height: 100vh;

  .logo {
    padding: 10px 0;
    text-align: center;

    img {
      width: 200px;
    }
  }

  .content {
    width: 80%;
    height: 50vh;
    max-width: 1000px;
    margin: 0 auto;
  }

  .new-content {
    background-color: #f4f3ef;
    padding-left: 200px;
    padding-right: 200px;
  }
}
