.choose-specialist-container {
  max-width: none !important;
  width: auto !important;
  overflow: hidden !important;

  .card {
    background: none;
    box-shadow: none;
  }

  .specialist-card {
    display: grid;
    grid-template-columns: 160px 1fr;
    height: 100%;
    padding: 10px;

    &.even {
      //background: #0da89a;
      background: var(--primary-color);
    }
    &.odd {
      //background: #0797af;
      background: var(--primary-color);
    }

    .specialist-card-image {
      text-align: center;
      img {
        border-radius: 50%;
        width: 100%;
        max-width: 160px;
        border: 1px solid white;
        padding-bottom: 10px;
      }
    }

    .specialist-card-info {
      padding-left: 10px;
      color: white;
    }

    .specialist-title {
      font-size: 1rem;
      font-weight: 600;
    }

    .specialist-bio {
      font-size: 0.8rem;
    }

    @media screen and (max-width: 526px) {
      display: block;
    }
  }

  .button-white {
    background-color: #FFFFFF !important;
    color: var(--primary-color) !important;
  }
}
