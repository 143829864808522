.dashboard-member-container {
  .card-service {
    text-align: left;

    .card-body {
      padding-bottom: 0 !important;
    }
  }
  .card-image {
    width: 80px;
  }
  .card-prod2 {
    ul li {
      list-style-type: disc;
      color: $selfguru-color;
    }
  }
  .card-icon.card-icon-status {
    padding-top: 0;
    i {
      font-size: 30px;
      width: 80px;
      height: 80px;
      line-height: 80px;
    }
  }
}

.user-overview-table {
  .table-responsive {
    overflow-y: hidden;
  }
  .user-avatar img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  .user-name {
    line-height: 32px;
  }
  .program-name, .program-progress {
    height: 22px;
    margin-bottom: 5px;  
  }
  .program-progress {
    display: grid;
    grid-template-columns: 80px auto;
    align-items: center;

    .progress {
      margin-right: 5px;
    }
  }
}