.appointment-create-calendar {
  .rbc-allday-cell {
    display: none;
  }
 
  .rbc-day-slot .rbc-event {
    min-height: 40px;
  }

  .rbc-day-slot {
    .btn-schedule {
      margin: 0;
      padding: 5px 10px !important;
    }
  }

  &.week-view {
    .rbc-event-content {
      span {
        display: none;
      }
      .btn-schedule {
        margin: 0 !important;
      }
    }
  }
}

.appointment-create-page {
  .react-select {
    min-width: 400px;
    width: 50%;
  }
}