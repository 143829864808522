.product-purchase-container {
  .card-body {
    padding-top: 30px;
  }
  .product-title {
    text-transform: uppercase;
  }
  .product-image {
    height: 120px;
  }
  .product-desc1 {
    padding: 10px;
    font-size: 1.2em;
  }
  ul {
    padding-left: 20px;
  }
  .product-desc3 {
    padding-top: 20px;
    p {
      margin-bottom: 3px;
    }
  }

  .product-payment-block {
    border: 3px solid $selfguru-bg;
    padding: 5px 10px;

    .product-price {
      margin: 0;
      font-weight: 600;
    }
    button {
      margin-bottom: 20px;
    }
  }
}