.performance-grou {
  &.icon-result {
    font-size: 16px;
    cursor: pointer;
    margin-left: 3px;
  }

  &.modal-header {
    display: flex !important;
  }
}
